import routerOptions0 from "/home/runner/work/web-app/web-app/node_modules/.pnpm/nuxt@3.15.4_@types+node@20.17.17_eslint@9.20.1_typescript@5.7.3_vite@6.1.0/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/home/runner/work/web-app/web-app/node_modules/.pnpm/@nuxt+ui-pro@1.7.0_typescript@5.7.3_vite@6.1.0_vue@3.5.13/node_modules/@nuxt/ui-pro/modules/pro/runtime/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export const hashMode = routerOptions0.hashMode ?? false
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}