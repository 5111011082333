import validate from "/home/runner/work/web-app/web-app/node_modules/.pnpm/nuxt@3.15.4_@types+node@20.17.17_eslint@9.20.1_typescript@5.7.3_vite@6.1.0/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/home/runner/work/web-app/web-app/node_modules/.pnpm/nuxt@3.15.4_@types+node@20.17.17_eslint@9.20.1_typescript@5.7.3_vite@6.1.0/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  admin: () => import("/home/runner/work/web-app/web-app/src/layers/auth/src/middleware/admin.ts"),
  auth: () => import("/home/runner/work/web-app/web-app/src/layers/auth/src/middleware/auth.ts"),
  guest: () => import("/home/runner/work/web-app/web-app/src/layers/auth/src/middleware/guest.ts"),
  leader: () => import("/home/runner/work/web-app/web-app/src/layers/auth/src/middleware/leader.ts")
}