import payload_plugin_MR0izqZG06 from "/home/runner/work/web-app/web-app/node_modules/.pnpm/nuxt-vuefire@1.0.5_@firebase+app-types@0.9.3_firebase-admin@12.7.0_firebase-functions@4.9.0_f_q7zbigvto4evxf34uygx6q54cu/node_modules/nuxt-vuefire/dist/runtime/payload-plugin.js";
import revive_payload_client_4tdKcdoC7k from "/home/runner/work/web-app/web-app/node_modules/.pnpm/nuxt@3.15.4_@types+node@20.17.17_eslint@9.20.1_typescript@5.7.3_vite@6.1.0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_x7Dpob7XSf from "/home/runner/work/web-app/web-app/node_modules/.pnpm/nuxt@3.15.4_@types+node@20.17.17_eslint@9.20.1_typescript@5.7.3_vite@6.1.0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_dndlbY9Xvd from "/home/runner/work/web-app/web-app/node_modules/.pnpm/nuxt@3.15.4_@types+node@20.17.17_eslint@9.20.1_typescript@5.7.3_vite@6.1.0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_fH2MkslClP from "/home/runner/work/web-app/web-app/node_modules/.pnpm/nuxt-site-config@2.2.21_vite@6.1.0_vue@3.5.13/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_mfPfCcZaL5 from "/home/runner/work/web-app/web-app/node_modules/.pnpm/nuxt@3.15.4_@types+node@20.17.17_eslint@9.20.1_typescript@5.7.3_vite@6.1.0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_0Evz6yf7uP from "/home/runner/work/web-app/web-app/node_modules/.pnpm/nuxt@3.15.4_@types+node@20.17.17_eslint@9.20.1_typescript@5.7.3_vite@6.1.0/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_QliYnVcvwF from "/home/runner/work/web-app/web-app/node_modules/.pnpm/nuxt@3.15.4_@types+node@20.17.17_eslint@9.20.1_typescript@5.7.3_vite@6.1.0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_RtZPwI5nSZ from "/home/runner/work/web-app/web-app/node_modules/.pnpm/nuxt@3.15.4_@types+node@20.17.17_eslint@9.20.1_typescript@5.7.3_vite@6.1.0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_OjXqdJcUAc from "/home/runner/work/web-app/web-app/node_modules/.pnpm/@pinia+nuxt@0.5.5_typescript@5.7.3_vue@3.5.13/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import plugin_9jT3W5OS8r from "/home/runner/work/web-app/web-app/.nuxt/runtime/plugin.ts";
import components_plugin_KR1HBZs4kY from "/home/runner/work/web-app/web-app/.nuxt/components.plugin.mjs";
import prefetch_client_Zw8IzzPEoA from "/home/runner/work/web-app/web-app/node_modules/.pnpm/nuxt@3.15.4_@types+node@20.17.17_eslint@9.20.1_typescript@5.7.3_vite@6.1.0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import slideovers_RtMT37GFv5 from "/home/runner/work/web-app/web-app/node_modules/.pnpm/@nuxt+ui@2.21.0_typescript@5.7.3_vite@6.1.0_vue@3.5.13/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_1KJvzz8QQh from "/home/runner/work/web-app/web-app/node_modules/.pnpm/@nuxt+ui@2.21.0_typescript@5.7.3_vite@6.1.0_vue@3.5.13/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_P2RAa7PYwj from "/home/runner/work/web-app/web-app/node_modules/.pnpm/@nuxt+ui@2.21.0_typescript@5.7.3_vite@6.1.0_vue@3.5.13/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_eNelkHx7DD from "/home/runner/work/web-app/web-app/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_eioRNf7eZQ from "/home/runner/work/web-app/web-app/node_modules/.pnpm/@nuxt+icon@1.10.3_vite@6.1.0_vue@3.5.13/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_client_kzadGAn1pz from "/home/runner/work/web-app/web-app/node_modules/.pnpm/nuxt-vuefire@1.0.5_@firebase+app-types@0.9.3_firebase-admin@12.7.0_firebase-functions@4.9.0_f_q7zbigvto4evxf34uygx6q54cu/node_modules/nuxt-vuefire/dist/runtime/app/plugin.client.js";
import vuefire_auth_plugin_client_cCwkwh1j5u from "/home/runner/work/web-app/web-app/.nuxt/vuefire-auth-plugin.client.mjs";
import vuefire_plugin_8cq4FnzXKb from "/home/runner/work/web-app/web-app/.nuxt/vuefire-plugin.mjs";
import plugin_client_uNzenawvzz from "/home/runner/work/web-app/web-app/node_modules/.pnpm/nuxt-vuefire@1.0.5_@firebase+app-types@0.9.3_firebase-admin@12.7.0_firebase-functions@4.9.0_f_q7zbigvto4evxf34uygx6q54cu/node_modules/nuxt-vuefire/dist/runtime/app-check/plugin.client.js";
import scrollbars_client_FOih5KHQ02 from "/home/runner/work/web-app/web-app/node_modules/.pnpm/@nuxt+ui-pro@1.7.0_typescript@5.7.3_vite@6.1.0_vue@3.5.13/node_modules/@nuxt/ui-pro/modules/pro/runtime/plugins/scrollbars.client.ts";
import presets_LjBUsGkmTP from "/home/runner/work/web-app/web-app/node_modules/.pnpm/@nuxt+ui-pro@1.7.0_typescript@5.7.3_vite@6.1.0_vue@3.5.13/node_modules/@nuxt/ui-pro/plugins/presets.ts";
import variables_FooAQA5Loc from "/home/runner/work/web-app/web-app/node_modules/.pnpm/@nuxt+ui-pro@1.7.0_typescript@5.7.3_vite@6.1.0_vue@3.5.13/node_modules/@nuxt/ui-pro/plugins/variables.ts";
import maska_3yl0YyDJq9 from "/home/runner/work/web-app/web-app/src/layers/components/src/plugins/maska.ts";
import api_TPJcntO808 from "/home/runner/work/web-app/web-app/src/layers/commons/src/plugins/api.ts";
import remoteConfig_client_JXGjZ3KVCq from "/home/runner/work/web-app/web-app/src/layers/commons/src/plugins/remoteConfig.client.ts";
export default [
  payload_plugin_MR0izqZG06,
  revive_payload_client_4tdKcdoC7k,
  unhead_x7Dpob7XSf,
  router_dndlbY9Xvd,
  _0_siteConfig_fH2MkslClP,
  payload_client_mfPfCcZaL5,
  navigation_repaint_client_0Evz6yf7uP,
  check_outdated_build_client_QliYnVcvwF,
  chunk_reload_client_RtZPwI5nSZ,
  plugin_vue3_OjXqdJcUAc,
  plugin_9jT3W5OS8r,
  components_plugin_KR1HBZs4kY,
  prefetch_client_Zw8IzzPEoA,
  slideovers_RtMT37GFv5,
  modals_1KJvzz8QQh,
  colors_P2RAa7PYwj,
  plugin_client_eNelkHx7DD,
  plugin_eioRNf7eZQ,
  plugin_client_kzadGAn1pz,
  vuefire_auth_plugin_client_cCwkwh1j5u,
  vuefire_plugin_8cq4FnzXKb,
  plugin_client_uNzenawvzz,
  scrollbars_client_FOih5KHQ02,
  presets_LjBUsGkmTP,
  variables_FooAQA5Loc,
  maska_3yl0YyDJq9,
  api_TPJcntO808,
  remoteConfig_client_JXGjZ3KVCq
]